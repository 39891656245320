import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Автомийка
			</title>
			<meta name={"description"} content={"Де кожен автомобіль має свою історію, а кожна мийка - свій розділ"} />
			<meta property={"og:title"} content={"Про нас | Автомийка"} />
			<meta property={"og:description"} content={"Де кожен автомобіль має свою історію, а кожна мийка - свій розділ"} />
			<meta property={"og:image"} content={"https://ua.pivoxlike.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.pivoxlike.com/img/546457832.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.pivoxlike.com/img/546457832.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.pivoxlike.com/img/546457832.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.pivoxlike.com/img/546457832.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.pivoxlike.com/img/546457832.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.pivoxlike.com/img/546457832.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--green"
			color="--dark"
			padding="80px 0"
			sm-padding="40px 0"
			background="--color-light"
		>
			<Text
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Наша подорож до блиску
			</Text>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
			Про нас
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
			Автомийка виникла як ідея створити автомийку, яка б не була схожа на рутинну роботу, а більше на задоволення як для автомобіля, так і для його власника. Ми ґрунтуємося на вірі в те, що кожен автомобіль заслуговує на блиск, який відображає гордість і радість його власника. Протягом багатьох років ми відточували свою майстерність, поєднуючи зручність сучасних автоматизованих мийок з комфортом зон самообслуговування, зберігаючи при цьому нашу прихильність до екологічної стійкості.
			</Text>
			<Box min-width="100px" min-height="100px">
				<Button
					type="link"
					font="--headline3"
					text-decoration-line="initial"
					color="--dark"
					border-radius="8px"
					border-width="1px"
					border-style="solid"
					border-color="--color-dark"
					background="rgba(0, 119, 204, 0)"
					href="/contacts"
				>
					Контакти
				</Button>
			</Box>
		</Section>
		<Section color="--dark" background="--color-light">
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
				Що нас відрізняє
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				У Автомийка ми дбаємо не лише про блиск - ми дбаємо про враження. Від моменту, коли ви в'їжджаєте до нас, до моменту, коли ви виїжджаєте на сяючому автомобілі, ми прагнемо зробити кожен ваш візит незабутнім. Ось що робить нас особливими:
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
				Точність і турбота:
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Наше обладнання відрегульоване до досконалості, що гарантує щоразу блискучу поверхню без подряпин.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
				Екологічно чисті методи: 
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Сталий розвиток лежить в основі нашої діяльності. Ми використовуємо перероблену воду та екологічно чисті миючі засоби, щоб захистити нашу планету.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
				Трохи розкоші: 
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Наші зручності створені з думкою про ваш комфорт. Розслабтеся в нашому лаунжі або випийте безкоштовної кави, поки чекаєте.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				
				sm-margin="24px 0 0 0"
			>
				<Text as="h3" font="--headline2" margin="10px 0">
				Вибір, що розширює можливості: 
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Обирайте одну з наших найсучасніших автоматизованих мийок або візьміть все під свій контроль у наших зонах самообслуговування. Ваш автомобіль - ваш вибір.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7" background="--color-light">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://ua.pivoxlike.com/img/2.jpg"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://ua.pivoxlike.com/img/3.jpg"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					У нас кожна мийка - це більше, ніж просто очищення вашого автомобіля, це створення історії про гордість, турботу та спільноту. Ми запрошуємо вас стати частиною нашої подорожі, де кожен візит додає нову блискучу главу до історії вашого автомобіля. Давайте створювати незабутні спогади, один блискучий автомобіль за раз.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});